import React from 'react'

import styled from "styled-components"

const StyledDiv = styled.div`
    text-align: center;
    background-color: #071435;
    color: #fff;
    div {
        padding: 8px;
    }
    width: 100%;
    z-index: 999;
`

const Announcement = () => {
    return (
        <StyledDiv>
            <div>
                👨‍💻 Looking for a developer? I'm available for work. Contact me at contact@ademhodzic.com
            </div>
        </StyledDiv>
    )
}

export default Announcement